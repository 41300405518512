import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { FullSaleTransactionDataFragment, UserType } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ViewBiddingEntry from './ViewBiddingEntry';

export type TopBidsProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const TopBids = ({ saleTransaction }: TopBidsProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const user = useCurrentUser();

    if (!saleTransaction.latestBiddingSessionEntry) {
        // no bidding session
        return null;
    }

    const entries = saleTransaction.latestBiddingSessionEntry.topBiddingEntries;

    if (!entries.length) {
        // no biddings
        return null;
    }

    if (user.type !== UserType.Admin && user.type !== UserType.Approver) {
        // user cannot see biddings
        return null;
    }

    return (
        <Grid xs={12} item>
            <Accordion title={t('saleTransactionsPage:sessionDetails.topBids.title')} defaultExpanded>
                <div>
                    {entries.map((entry, index) => (
                        <ViewBiddingEntry key={entry.id} entry={entry} index={index} />
                    ))}
                </div>
            </Accordion>
        </Grid>
    );
};

export default TopBids;
