import PubSub from 'pubsub-js';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { NotificationType } from '../api';
import { FlutterMeta, OneMonitoringData, PushContext, DownloadFileContext } from '../flutterInterface';
import { useRuntimeConfig } from '../runtimeConfig';

export type FlutterContext = FlutterMeta & {
    requestOneMonitoring: () => Promise<OneMonitoringData>;
    initializePush: () => Promise<PushContext>;
    checkBiometric: () => Promise<boolean>;
    sendDownloadFile: ({ filename, signedUrl }: DownloadFileContext) => Promise<boolean>;
};

const Context = createContext<FlutterContext | null>(null);

export type FlutterProviderProps = {
    children: JSX.Element | ReactNode;
};

export const useFlutter = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error('Flutter context not provided');
    }

    return context;
};

const FlutterProvider = ({ children }: FlutterProviderProps) => {
    const { flutterInterface } = useRuntimeConfig();
    const history = useHistory();

    const [meta, setMeta] = useState<FlutterMeta>({
        deviceFingerPrint: null,
        capabilities: {
            hasFingerPrint: false,
            hasOneMonitoring: false,
            hasFaceID: false,
        },
    });

    useEffect(() => {
        let mounted = true;

        flutterInterface.initialize().then(response => {
            if (mounted) {
                setMeta(response);
            }
        });

        return () => {
            mounted = false;
        };
    }, [setMeta, flutterInterface]);

    useEffect(() => {
        // todo later do actions on push received
        const token = flutterInterface.listenPushes(push => {
            const notificationType = push.type;
            if (notificationType) {
                switch (notificationType.toLowerCase()) {
                    case NotificationType.PendingValuationNotice.toLowerCase():
                    case NotificationType.HandoverDateChangeNotice.toLowerCase():
                    case NotificationType.PendingAcceptanceNotice.toLowerCase():
                    case NotificationType.PurchaseTransactionApprovalNotice.toLowerCase():
                    case NotificationType.PurchaseTransactionApprovedNotice.toLowerCase():
                    case NotificationType.PurchaseTransactionRejectedNotice.toLowerCase():
                        history.push(`/purchaseTransaction/${push.transactionId}`);
                        break;
                    case NotificationType.SalesTransactionApprovalNotice.toLowerCase():
                    case NotificationType.SalesTransactionApprovedNotice.toLowerCase():
                    case NotificationType.SalesTransactionRejectedNotice.toLowerCase():
                    case NotificationType.AwardedNotice.toLowerCase():
                        history.push(`/salesTransactions/${push.transactionId}`);
                        break;
                    case NotificationType.UpcomingBiddingNotice.toLowerCase():
                        history.push(`/biddingSession/${push.biddingId}`);
                        break;
                    default:
                        break;
                }
            }
        });

        return () => {
            // unsubscribe
            PubSub.unsubscribe(token);
        };
    }, [flutterInterface, history]);

    const context = useMemo(
        (): FlutterContext => ({
            ...meta,
            requestOneMonitoring: flutterInterface.getOneMonitoringData.bind(flutterInterface),
            initializePush: flutterInterface.initializePush.bind(flutterInterface),
            checkBiometric: flutterInterface.checkBiometric.bind(flutterInterface),
            sendDownloadFile: flutterInterface.sendDownloadFile.bind(flutterInterface),
        }),
        [meta, flutterInterface]
    );

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default FlutterProvider;
