import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '../../../api';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import SearchInputField from '../../../components/fields/SearchInputField';
import useCommonStyles from '../../../useCommonStyles';

export type UsersHeaderProps = {
    search: string;
    searchOnChange: (searchValue: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.secondary.contrastText,
            '& fieldset': {
                border: 'none',
            },
        },
    })
);

const NotificationHeader = ({ search, searchOnChange }: UsersHeaderProps) => {
    const { t } = useTranslation(['notifications', 'common']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const filterOptions = useMemo(
        () => [
            { value: 'all', label: t('notifications:filters.all') },
            { value: NotificationType.AwardedNotice, label: t('notifications:filters.awardedNotice') },
            { value: NotificationType.HandoverDateChangeNotice, label: t('notifications:filters.handoverDateChange') },
            { value: NotificationType.PendingAcceptanceNotice, label: t('notifications:filters.pendingAcceptance') },
            { value: NotificationType.PendingValuationNotice, label: t('notifications:filters.pendingValuation') },
            { value: NotificationType.SignUpApproval, label: t('notifications:filters.signUpApproval') },
            { value: NotificationType.SignUpReject, label: t('notifications:filters.signUpReject') },
            { value: NotificationType.UpcomingBiddingNotice, label: t('notifications:filters.upcomingBidding') },
        ],
        [t]
    );

    return (
        <Box alignItems="center" className={commonStyles.header} display="flex">
            <Typography className={commonStyles.title}>{t('notifications:header.title')}</Typography>
            <Box display="flex" ml="auto" width={550}>
                <SearchInputField onChange={searchOnChange} placeholder={t('common:search')} value={search} />
                <Box ml={3} width="100%">
                    <OutlinedSelectField
                        className={styles.root}
                        inputProps={{
                            classes: {
                                icon: commonStyles.fillPrimary2,
                                outlined: commonStyles.secondaryBgColor,
                                select: commonStyles.defaultBorderRadius,
                            },
                        }}
                        name="filter"
                        options={filterOptions}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default NotificationHeader;
