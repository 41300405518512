import {
    Accordion as MaterialAccordion,
    AccordionProps as MaterialAccordionProps,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Divider,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cs from 'classnames';
import { ReactElement } from 'react';
import useCommonStyles from '../useCommonStyles';

export interface AccordionProps extends MaterialAccordionProps {
    title: string;
    children: ReactElement | null;
    useCardDesign?: boolean;
    hideTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        accordion: {
            boxShadow: '0px 0px 20px #00000029',
            [theme.breakpoints.down('sm')]: {
                boxShadow: 'none',
                backgroundColor: 'transparent',
            },
        },
        root: {
            '& .MuiButtonBase-root.Mui-expanded': {
                minHeight: `${theme.spacing(6)}px !important`,
                height: `${theme.spacing(6)}px !important`,
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: 0,
            },
        },
        title: {
            fontWeight: 'bold',
        },
    })
);

const Accordion = ({ title, children, useCardDesign, hideTitle, ...props }: AccordionProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    return (
        <MaterialAccordion
            className={styles.root}
            classes={{
                root: useCardDesign
                    ? commonStyles.cardBoxShadow
                    : cs(commonStyles.removeBoxShadow, commonStyles.backgroundTransparent),
            }}
            {...props}
        >
            {!hideTitle && (
                <AccordionSummary expandIcon={<ExpandMoreIcon color={useCardDesign ? 'secondary' : 'primary'} />}>
                    <Typography className={styles.title} color={useCardDesign ? 'secondary' : 'primary'}>
                        {title}
                    </Typography>
                </AccordionSummary>
            )}
            {useCardDesign && <Divider />}
            <AccordionDetails>{children}</AccordionDetails>
        </MaterialAccordion>
    );
};

export default Accordion;
