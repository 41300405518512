import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SearchInputField from '../../../components/fields/SearchInputField';
import useCommonStyles from '../../../useCommonStyles';

export type ApprovalsHeaderProps = {
    search: string;
    searchOnChange: (searchValue: string) => void;
};

const ApprovalsHeader = ({ search, searchOnChange }: ApprovalsHeaderProps) => {
    const { t } = useTranslation(['approvalsPage', 'common']);
    const commonStyles = useCommonStyles();

    return (
        <Box alignItems="center" className={commonStyles.header} display="flex">
            <Typography className={commonStyles.title}>{t('approvalsPage:title')}</Typography>
            <Box ml="auto">
                <SearchInputField onChange={searchOnChange} placeholder={t('common:search')} value={search} />
            </Box>
        </Box>
    );
};

export default ApprovalsHeader;
