import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { SaleTransactionStage } from '../api';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        biddingOpen: {
            color: '#00B7C6',
            border: '1px solid #00B7C6',
        },
        closed: {
            color: '#8B8B8B',
            border: '1px solid #BFBFBF',
        },
        completed: {
            color: '#15A764',
            border: '1px solid #15A764',
        },
        forAwarding: {
            color: '#FF00FF',
            border: '1px solid #FF00FF',
        },
        forHandover: {
            color: '#E6CD41',
            border: '1px solid #E3CC5C',
        },
        new: {
            color: '#9741D6',
            border: '1px solid #8C4CCF',
        },
        pendingApproval: {
            color: '#FF8900',
            border: '1px solid #FFA945',
        },
        upcomingBidding: {
            color: '#0000FF',
            border: '1px solid #0000FF',
        },
    })
);

const useSalesTransactionStatusStyles = (transactionStatus: SaleTransactionStage) => {
    const styles = useStyles();

    return useMemo(() => {
        switch (transactionStatus) {
            case SaleTransactionStage.BiddingOpen:
                return styles.biddingOpen;

            case SaleTransactionStage.Closed:
                return styles.closed;

            case SaleTransactionStage.Completed:
                return styles.completed;

            case SaleTransactionStage.ForAwarding:
                return styles.forAwarding;

            case SaleTransactionStage.ForHandover:
                return styles.forHandover;

            case SaleTransactionStage.New:
                return styles.new;

            case SaleTransactionStage.PendingApproval:
                return styles.pendingApproval;

            case SaleTransactionStage.UpcomingBidding:
                return styles.upcomingBidding;

            default:
                return styles.new;
        }
    }, [styles, transactionStatus]);
};

export default useSalesTransactionStatusStyles;
