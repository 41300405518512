import { createTheme } from '@material-ui/core/styles';

const portalTheme = createTheme({
    typography: {
        button: {
            textTransform: 'none',
        },
        fontFamily: 'Noto Sans JP !important',
    },

    palette: {
        primary: {
            main: '#1C1C1C',
        },

        secondary: {
            main: '#ED2A74',
            contrastText: '#fff',
        },
    },
});

export default portalTheme;
