import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserActivationStage, UserType } from '../../../api';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import SearchInputField from '../../../components/fields/SearchInputField';
import useCommonStyles from '../../../useCommonStyles';

export type UsersHeaderProps = {
    search: string;
    searchOnChange: (searchValue: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.secondary.contrastText,
            '& fieldset': {
                border: 'none',
            },
        },
    })
);

const UsersHeader = ({ search, searchOnChange }: UsersHeaderProps) => {
    const { t } = useTranslation(['usersPage', 'common']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const statuses = useMemo(
        () => [
            { value: 'all', label: t('usersPage:filter.allUsers') },
            { value: null, label: t('usersPage:filter.state'), isSubHeader: true },
            { value: UserActivationStage.New, label: t('usersPage:filter.new') },
            { value: UserActivationStage.Active, label: t('usersPage:filter.enabled') },
            { value: UserActivationStage.Disabled, label: t('usersPage:filter.disabled') },
            { value: UserActivationStage.Rejected, label: t('usersPage:filter.rejected') },
            { value: null, label: t('usersPage:filter.type'), isSubHeader: true },
            { value: UserType.Admin, label: t('usersPage:userType.admin') },
            { value: UserType.Approver, label: t('usersPage:userType.approver') },
            { value: UserType.Dealer, label: t('usersPage:userType.dealer') },
            { value: UserType.SaleConsultant, label: t('usersPage:userType.saleConsultant') },
            { value: UserType.ValuationTeam, label: t('usersPage:userType.valuationTeam') },
        ],
        [t]
    );

    return (
        <Box alignItems="center" className={commonStyles.header} display="flex">
            <Typography className={commonStyles.title}>{t('usersPage:title')}</Typography>
            <Box display="flex" ml="auto" width={550}>
                <SearchInputField onChange={searchOnChange} placeholder={t('common:search')} value={search} />
                <Box ml={3} width="100%">
                    <OutlinedSelectField
                        className={styles.root}
                        inputProps={{
                            classes: {
                                icon: commonStyles.fillPrimary2,
                                outlined: commonStyles.secondaryBgColor,
                                select: commonStyles.defaultBorderRadius,
                            },
                        }}
                        name="filter"
                        options={statuses}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default UsersHeader;
