import React from 'react';
import { useTranslation } from 'react-i18next';
import { FullBiddingDataFragment } from '../../../../../api';
import Accordion from '../../../../../components/Accordion';
import SessionForm from '../../Forms/SessionForm';

export type EditSessionAccordionProps = {
    bidding: FullBiddingDataFragment;
};

const EditSessionAccordion = ({ bidding }: EditSessionAccordionProps) => {
    const { t } = useTranslation(['biddingSessionsPage']);

    return (
        <Accordion title={t('biddingSessionsPage:editTitle')} defaultExpanded useCardDesign>
            <SessionForm editMode />
        </Accordion>
    );
};

export default EditSessionAccordion;
