import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FullUserDataFragment } from '../../../api';
import useCommonStyles from '../../../useCommonStyles';
import UserEditDialog from './UserEditDialog';
import UsersRow from './UsersRow';

export type UsersTableProps = {
    users: FullUserDataFragment[];
    onUpdates: () => void;
};

const UsersTable = ({ users, onUpdates }: UsersTableProps) => {
    const [activeUser, setActiveUser] = useState<FullUserDataFragment | null>(null);

    const openEditForm = (user: FullUserDataFragment | null): void => {
        setActiveUser(user);
    };

    const { t } = useTranslation(['usersPage']);
    const commonStyles = useCommonStyles();

    return (
        <Box mb={3}>
            <Table className={commonStyles.table}>
                <TableHead>
                    <TableRow className={commonStyles.tableRow}>
                        <TableCell>{t('usersPage:headers.fullName')}</TableCell>
                        <TableCell>{t('usersPage:headers.email')}</TableCell>
                        <TableCell>{t('usersPage:headers.mobile')}</TableCell>
                        <TableCell>{t('usersPage:headers.type')}</TableCell>
                        <TableCell>{t('usersPage:headers.additionalInformation')}</TableCell>
                        <TableCell>{t('usersPage:headers.registeredOn')}</TableCell>
                        <TableCell>{t('usersPage:headers.actions')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map(user => (
                        <UsersRow key={user.id} openEditForm={() => openEditForm(user)} user={user} />
                    ))}

                    <UserEditDialog
                        onClose={() => setActiveUser(null)}
                        onUpdates={onUpdates}
                        open={!!activeUser}
                        user={activeUser}
                    />
                </TableBody>
            </Table>
        </Box>
    );
};

export default UsersTable;
