import { Card, CardContent, Box, Typography, CardMedia, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { cardDefaultImage } from '../../../../../server/schema/constants';
import { useCurrentUser } from '../../../../Session';
import { FullSaleTransactionDataFragment, UserType, BiddingStatus } from '../../../../api';
import useCommonStyles from '../../../../useCommonStyles';
import usePublic from '../../../../utilities/usePublic';
import useSalesTransactionStatus from '../../../../utilities/useSalesTransactionStatus';
import useSalesTransactionStatusStyles from '../../../../utilities/useSalesTransactionStatusStyles';
import useVehicleLogo from '../../../../utilities/useVehicleLogo';
import BiddingLiveDuration from './BiddingLiveDuration';

export type VehicleInfoProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        registrationNo: {
            color: 'rgb(0, 0, 0, 0.8)',
        },
        carLogo: {
            marginLeft: 'auto',
            height: '3rem',
        },
        carImage: {
            height: theme.spacing(30),
            borderRadius: theme.spacing(1),
        },
    })
);

const VehicleInfo = ({ saleTransaction }: VehicleInfoProps) => {
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const carLogo = useVehicleLogo(saleTransaction.vehicle.make);
    const defaultImage = usePublic(cardDefaultImage);
    const currentUser = useCurrentUser();

    const transactionStatus = useSalesTransactionStatus(saleTransaction.stage);
    const statusStyle = useSalesTransactionStatusStyles(saleTransaction.stage);

    const showBiddingLiveDuration =
        currentUser.type === UserType.Dealer &&
        [BiddingStatus.Upcoming, BiddingStatus.Current].includes(saleTransaction.latestBiddingSession?.status);

    return (
        <Box position="relative">
            {!isSmall && (
                <>
                    <Box position="relative">
                        <CardMedia
                            className={styles.carImage}
                            image={saleTransaction.frontPagePhoto?.signedUrl || defaultImage}
                        />
                        {showBiddingLiveDuration && <BiddingLiveDuration transaction={saleTransaction} />}
                    </Box>
                    <Box display="flex" mt={showBiddingLiveDuration && !isSmall ? 8 : 2}>
                        <Box>
                            <Box display="flex">
                                <Typography className={cs(commonStyles.primary, commonStyles.fontWeightBold)}>
                                    {`${saleTransaction.vehicle.make} ${saleTransaction.vehicle.model}`}
                                </Typography>
                                <Typography
                                    className={
                                        isSmall
                                            ? cs(
                                                  commonStyles.commonTransactionStatus,
                                                  commonStyles.transactionStatus2,
                                                  statusStyle
                                              )
                                            : cs(commonStyles.commonTransactionStatus, commonStyles.transactionStatus)
                                    }
                                >
                                    {transactionStatus}
                                </Typography>
                            </Box>
                            <Typography className={cs(styles.registrationNo, commonStyles.fontWeightLight)}>
                                {saleTransaction.vehicle.number}
                            </Typography>
                        </Box>
                        <img alt="carLogo" className={commonStyles.vehicleLogo} src={carLogo} />
                    </Box>
                </>
            )}
            {isSmall && (
                <Box>
                    <Card className={commonStyles.card}>
                        <CardContent>
                            <Box display="flex">
                                <Box>
                                    <Box display="flex">
                                        <Typography className={cs(commonStyles.primary, commonStyles.fontWeightBold)}>
                                            {`${saleTransaction.vehicle.make} ${saleTransaction.vehicle.model}`}
                                        </Typography>
                                        <Typography
                                            className={
                                                isSmall
                                                    ? cs(
                                                          commonStyles.commonTransactionStatus,
                                                          commonStyles.transactionStatus2,
                                                          statusStyle
                                                      )
                                                    : cs(
                                                          commonStyles.commonTransactionStatus,
                                                          commonStyles.transactionStatus
                                                      )
                                            }
                                        >
                                            {transactionStatus}
                                        </Typography>
                                    </Box>
                                    <Typography className={cs(styles.registrationNo, commonStyles.fontWeightLight)}>
                                        {saleTransaction.vehicle.number}
                                    </Typography>
                                </Box>
                                <img alt="carLogo" className={commonStyles.vehicleLogo} src={carLogo} />
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            )}
        </Box>
    );
};

export default VehicleInfo;
