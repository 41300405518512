import { Box, Button, Grid, FormControl, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import {
    UserType,
    FullSaleTransactionDataFragment,
    SaleTransactionStage,
    useUpdateSaleTransactionToForHandoverMutation,
    useUpdateSaleTransactionToPendingApprovalMutation,
    useUpdateSaleTransactionToCompletedMutation,
    useUpdateSaleTransactionToClosedMutation,
} from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';
import useCommonStyles from '../../../../useCommonStyles';
import BiddingBlock from './BiddingBlock';
import ErrorDialog from './ErrorDialog';
import SelectBidDialog from './SelectBidDialog';
import TopBids from './TopBids';

export type BiddingTabTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        changeStageGridItem: {
            [theme.breakpoints.up('sm')]: {
                order: 1,
            },
        },
        closeTransactionLabel: {
            color: '#A5A5A5',
        },
    })
);

const BiddingTab = ({ saleTransaction }: BiddingTabTabProps) => {
    const [openSelectAwardee, setOpenSelectAwardee] = useState(false);
    const [validForApprovalFields, setValidForApprovalFields] = useState<string[] | null>(null);
    const [selectAwardeeRequireFields, setSelectAwardeeRequireFields] = useState<string[] | null>(null);

    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const currentUser = useCurrentUser();
    const styles = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const useCardDesignForAccordion = !isSmall;

    const variables = { transactionId: saleTransaction.id };
    const [updateToHandover] = useUpdateSaleTransactionToForHandoverMutation({ variables });
    const [updateToPendingApproval] = useUpdateSaleTransactionToPendingApprovalMutation({ variables });
    const [updateToCompleted] = useUpdateSaleTransactionToCompletedMutation({ variables });
    const [updateToClosed] = useUpdateSaleTransactionToClosedMutation({ variables });

    const onUpdateToHandover = useCallback(() => updateToHandover(), [updateToHandover]);

    const onUpdateToCompleted = useCallback(() => updateToCompleted(), [updateToCompleted]);
    const onUpdateToClosed = useCallback(() => updateToClosed(), [updateToClosed]);

    const checkValidToSelectAwardee = useCallback(() => {
        let isValidToSelectAwardee = true;
        const fields: string[] = [];

        if (!saleTransaction.handover.personResponsible) {
            isValidToSelectAwardee = false;
            fields.push(t('saleTransactionsPage:handover.personResponsible'));
        }

        if (!saleTransaction.handover.targetHandoverDateTime) {
            isValidToSelectAwardee = false;
            fields.push(t('saleTransactionsPage:handover.targetHandoverDate'));
            fields.push(t('saleTransactionsPage:handover.targetHandoverTime'));
        }

        if (!saleTransaction.handover.vehicleSalesAgreementNo) {
            isValidToSelectAwardee = false;
            fields.push(t('saleTransactionsPage:handover.vsaNo'));
        }

        if (isValidToSelectAwardee) {
            setOpenSelectAwardee(true);
            setSelectAwardeeRequireFields(null);
        } else {
            setSelectAwardeeRequireFields(fields);
        }
    }, [
        saleTransaction.handover.personResponsible,
        saleTransaction.handover.targetHandoverDateTime,
        saleTransaction.handover.vehicleSalesAgreementNo,
        t,
    ]);

    const onUpdateToPendingApproval = useCallback(() => {
        if (saleTransaction.handover.vsaAttachment) {
            updateToPendingApproval();
            setValidForApprovalFields(null);
        } else {
            setValidForApprovalFields(t('saleTransactionsPage:handover.vsaAttachment'));
        }
    }, [saleTransaction.handover.vsaAttachment, t, updateToPendingApproval]);

    const { latestBiddingSession, latestBiddingSessionEntry } = saleTransaction;

    const lastBid = latestBiddingSessionEntry?.lastUserBid?.amount || null;

    const showSelectAwardee =
        saleTransaction.stage === SaleTransactionStage.ForAwarding &&
        [UserType.Admin, UserType.Approver].includes(currentUser.type);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('saleTransactionsPage:sessionDetails.title')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    latestBiddingSession
                                        ? t('common:formats.longDateTime', {
                                              date: new Date(saleTransaction.latestBiddingSession.startPeriod),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('saleTransactionsPage:sessionDetails.bidOpeningOn')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    latestBiddingSession
                                        ? t('common:formats.longDateTime', {
                                              date: new Date(saleTransaction.latestBiddingSession.endPeriod),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('saleTransactionsPage:sessionDetails.bidClosingDate')}
                            />
                        </Grid>
                        {currentUser.type === UserType.Dealer && lastBid && (
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={lastBid ? `${t('common:currency')}${lastBid}` : t('common:blankValue')}
                                    label={t('saleTransactionsPage:bidDrawer.lastBid')}
                                />
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    !saleTransaction.latestBiddingSession?.notes
                                        ? t('common:blankValue')
                                        : saleTransaction.latestBiddingSession.notes
                                }
                                display="block"
                                label={t('saleTransactionsPage:sessionDetails.bidNotes')}
                            />
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>
            {showSelectAwardee && (
                <Grid xs={12} item>
                    <Accordion
                        title={t('saleTransactionsPage:sessionDetails.awardeeTitle')}
                        useCardDesign={useCardDesignForAccordion}
                        defaultExpanded
                    >
                        <Grid spacing={1} container>
                            <Grid xs={12} item>
                                <Box display="flex" justifyContent="column">
                                    <Button
                                        className={cs(commonStyles.removeBoxShadow, commonStyles.defaultBorderRadius)}
                                        color="primary"
                                        onClick={checkValidToSelectAwardee}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:sessionDetails.selectAwardee')}
                                    </Button>
                                </Box>
                            </Grid>
                            <ErrorDialog
                                fields={selectAwardeeRequireFields}
                                onClose={() => setSelectAwardeeRequireFields(null)}
                            />
                        </Grid>
                    </Accordion>
                </Grid>
            )}

            <SelectBidDialog
                onClose={() => setOpenSelectAwardee(false)}
                open={openSelectAwardee}
                transaction={saleTransaction}
            />

            {![
                SaleTransactionStage.ForHandover,
                SaleTransactionStage.PendingApproval,
                SaleTransactionStage.Closed,
                SaleTransactionStage.Completed,
            ].includes(saleTransaction.stage) && (
                <>
                    <TopBids saleTransaction={saleTransaction} />
                    <BiddingBlock transaction={saleTransaction} />
                </>
            )}

            {currentUser.type === UserType.ValuationTeam && (
                <>
                    {saleTransaction.stage === SaleTransactionStage.ForHandover && (
                        <>
                            <Grid className={styles.changeStageGridItem} lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(commonStyles.removeBoxShadow, commonStyles.defaultBorderRadius)}
                                        color="primary"
                                        onClick={onUpdateToPendingApproval}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:button.requestApproval')}
                                    </Button>
                                </FormControl>
                            </Grid>

                            <ErrorDialog
                                fields={validForApprovalFields}
                                onClose={() => setValidForApprovalFields(null)}
                            />
                        </>
                    )}

                    {[
                        SaleTransactionStage.ForAwarding,
                        SaleTransactionStage.ForHandover,
                        SaleTransactionStage.PendingApproval,
                        SaleTransactionStage.Completed,
                    ].includes(saleTransaction.stage) && (
                        <Grid lg={6} md={6} sm={12} xs={12} item>
                            <FormControl fullWidth>
                                <Button
                                    classes={{ label: styles.closeTransactionLabel }}
                                    onClick={onUpdateToClosed}
                                    size="large"
                                    variant="outlined"
                                >
                                    {t('saleTransactionsPage:button.close')}
                                </Button>
                            </FormControl>
                        </Grid>
                    )}
                </>
            )}

            {
                // eslint-disable-next-line max-len
                currentUser.type === UserType.Approver &&
                    saleTransaction.stage === SaleTransactionStage.PendingApproval && (
                        <>
                            <Grid className={styles.changeStageGridItem} lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(commonStyles.removeBoxShadow, commonStyles.defaultBorderRadius)}
                                        color="primary"
                                        onClick={onUpdateToCompleted}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('saleTransactionsPage:button.approve')}
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid lg={6} md={6} sm={12} xs={12} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.defaultBorderRadius}
                                        color="primary"
                                        onClick={onUpdateToHandover}
                                        size="large"
                                        variant="outlined"
                                    >
                                        {t('saleTransactionsPage:button.reject')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        </>
                    )
            }

            {(currentUser.type === UserType.Admin || currentUser.type === UserType.Approver) &&
                [
                    SaleTransactionStage.ForAwarding,
                    SaleTransactionStage.ForHandover,
                    SaleTransactionStage.PendingApproval,
                    SaleTransactionStage.Completed,
                ].includes(saleTransaction.stage) && (
                    <Grid xs={12} item>
                        <FormControl fullWidth>
                            <Button
                                classes={{ label: styles.closeTransactionLabel }}
                                onClick={onUpdateToClosed}
                                size="large"
                                variant="outlined"
                            >
                                {t('saleTransactionsPage:button.close')}
                            </Button>
                        </FormControl>
                    </Grid>
                )}
        </Grid>
    );
};

export default BiddingTab;
