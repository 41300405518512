import { alpha, makeStyles } from '@material-ui/core/styles';

const useCommonStyles = makeStyles(theme => ({
    '@global': {
        a: {
            cursor: 'pointer',
        },
    },

    primary: {
        color: `${theme.palette.primary.main} !important`,
    },

    primary2: {
        color: `${theme.palette.secondary.contrastText} !important`,
    },

    primary2Border: {
        borderColor: `${theme.palette.secondary.contrastText} !important`,
    },

    primaryFont: {
        fontFamily: 'Noto Sans JP !important',
    },

    secondary: {
        color: `${theme.palette.secondary.main} !important`,
    },

    secondaryBorder: {
        borderColor: `${theme.palette.secondary.main} !important`,
    },

    secondary2: {
        color: '#717073',
    },

    secondary3: {
        color: '#EDEDED',
    },

    secondaryFont: {
        fontFamily: 'Noto Sans JP !important',
    },

    disabledSecondaryButton: {
        opacity: '50%',
        '&.MuiButton-contained': {
            backgroundColor: `${theme.palette.secondary.main} !important`,
            color: `${theme.palette.secondary.contrastText} !important`,
        },
        '&.MuiButton-outlined': {
            borderColor: `${theme.palette.secondary.main} !important`,
            color: `${theme.palette.secondary.main} !important`,
        },
    },

    fillPrimary2: {
        color: `${theme.palette.secondary.contrastText} !important`,
    },

    fillSecondary: {
        fill: `${theme.palette.secondary.main} !important`,
    },

    secondaryBgColor: {
        backgroundColor: `${theme.palette.secondary.main} !important`,
    },

    lightBgColor: {
        backgroundColor: theme.palette.primary.contrastText,
    },

    smallFontSize: {
        fontSize: '0.875rem !important',
    },

    normalFontSize: {
        fontSize: '1rem',
    },

    bigFontSize: {
        fontSize: '1.125rem',
    },

    fontWeightLight: {
        fontWeight: 300,
    },

    fontWeightRegular: {
        fontWeight: 400,
    },

    fontWightMedium: {
        fontWeight: 500,
    },

    fontWeightBold: {
        fontWeight: 700,
    },

    dialog: {
        borderRadius: '10px',
        border: `1px solid ${theme.palette.secondary.main}`,
    },

    dialogDescription: {
        color: alpha(theme.palette.primary.main, 0.65),
        fontSize: '0.875rem !important',
    },

    onlyDesktopAndTablet: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'inherit',
        },
    },

    onlyMobile: {
        display: 'inherit',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },

    placeholderOpacity: {
        opacity: '40%',
    },

    avatarSmall: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },

    avatarBig: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },

    avatarExtraBig: {
        width: theme.spacing(11),
        height: theme.spacing(11),
    },

    cardContent: {
        padding: `${theme.spacing(4)}px !important`,
    },

    displayFlex: {
        display: 'flex',
    },

    fullWidth: {
        width: '100%',
    },

    fullHeight: {
        height: '100%',
    },

    autoMarginLeft: {
        marginLeft: 'auto',
    },

    autoMarginRight: {
        marginRight: 'auto',
    },

    formLabel: {
        marginBottom: theme.spacing(1),
    },

    table: {
        '&.MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '0 1rem',
        },
    },

    tableRow: {
        '& > th, & > td': {
            borderBottom: 'none',
            backgroundColor: '#FFFFFF',
            color: '#717073',

            '&:first-child': {
                borderRadius: '0.5rem 0 0 0.5rem',
            },

            '&:last-child': {
                borderRadius: '0 0.5rem 0.5rem 0',
                width: 'auto',
            },
        },

        '& > th': {
            color: '#A3A3A3',
        },
    },

    buttonGroupHeader: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(3),
    },

    buttonGroup: {
        '& > *': {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                margin: theme.spacing(1),
            },
            '&:first-child': {
                marginLeft: 0,
            },
        },
    },

    header: {
        [theme.breakpoints.up('sm')]: {
            flexFlow: 'row wrap',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    },

    disabledLink: {
        opacity: '0.5',
        cursor: 'default',
    },

    vehicleLogo: {
        marginLeft: 'auto',
        maxHeight: '3.5rem',
        maxWidth: '7rem',
        height: 'fit-content',
    },

    rightTextAlign: {
        textAlign: 'right',
    },

    ellipsisTextOverflow: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    breakAllWordBreak: {
        wordBreak: 'break-all',
    },

    subMenusRoot: {
        borderRadius: theme.spacing(6),
        border: '1px solid #C4C4C4',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#F6F6F6',
            border: 'none',
        },
    },

    subMenusTextColor: {
        '&.Mui-selected': {
            color: `${theme.palette.primary.contrastText} !important`,
        },
    },

    subMenusindicator: {
        display: 'none',
    },

    subMenuTabRoot: {
        width: '50%',
        color: '#696969',
        maxWidth: '50% !important',
        '& > span.MuiTab-wrapper': {
            [theme.breakpoints.down('xs')]: {
                maxWidth: theme.spacing(15),
            },
        },
    },

    subMenuTabSelected: {
        borderRadius: theme.spacing(6),
        backgroundColor: theme.palette.primary.main,
    },

    removeBoxShadow: {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
    },

    commonTransactionStatus: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        width: 'fit-content',
        height: 'fit-content',
        fontSize: '14px',
    },

    transactionStatus: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
    },

    transactionStatus2: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.contrastText,
    },

    card: {
        boxShadow: 'none',
        [theme.breakpoints.down('sm')]: {
            boxShadow: '0px 0px 20px #00000029',
        },
        borderRadius: theme.spacing(1),
    },

    cardBoxShadow: {
        boxShadow: '0px 0px 20px #00000029',
    },

    title: {
        fontWeight: 'bold',
        fontSize: '1.25rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },

    divider: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },

    bottomNavigation: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        height: `${theme.spacing(10)}px !important`,
        boxShadow: '0px 0px 20px #00000010',
        borderRadius: '20px 20px 0px 0px',
    },

    bottomNavigationAction: {
        color: '#b1b1b1 !important',
        alignItems: 'flex-start',
        paddingTop: `${theme.spacing(1.5)}px !important`,
        '&.Mui-selected': {
            paddingTop: `${theme.spacing(1.5)}px !important`,
        },
    },

    bottomNavigationSelected: {
        color: theme.palette.primary.main,
        '& svg': {
            fill: theme.palette.primary.main,
        },
    },

    backgroundTransparent: {
        backgroundColor: 'transparent',
    },

    defaultBorderRadius: {
        borderRadius: `${theme.spacing(1)}px !important`,
    },

    fabRoot: {
        backgroundColor: `${theme.palette.secondary.contrastText} !important`,
        color: `${theme.palette.secondary.main} !important`,
        boxShadow: '0px 0px 30px #00000020 !important',
    },
}));

export default useCommonStyles;
