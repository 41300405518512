import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useCallback, useState } from 'react';
import { DownloadFileType, PreviewPurchaseTransactionDataFragment, PurchaseTransactionStage } from '../../../api';
import ListPagination from '../../../components/ListPagination';
import { useLoading } from '../../../components/LoadingProvider';
import NoRecordsFound from '../../../components/NoRecordsFound';
import {
    DownloadListFn,
    PurchaseTransactionsBody,
    PurchaseTransactionsFooter,
    PurchaseTransactionsHeader,
} from '../../PurchaseTransactions/components';
import FilterDialog from './PurchaseFilterDialog';
import FilterMenu from './PurchaseFilterMenu';

export type PurchaseHandoverTabProps = {
    search: string;
    title?: string;
    purchaseTransactions: PreviewPurchaseTransactionDataFragment[];
    page: number;
    count: number;
    pageSize: number;
    stage: PurchaseTransactionStage | 'all';
    setStage: (stage: PurchaseTransactionStage | 'all') => void;
    createdAt: Date | null;
    setCreatedAt: (createdAt: Date | null) => void;
    setActivePage: (page: number) => void;
    searchOnChange: (searchValue: string) => void;
    downloadPurchaseTransactionsFile: DownloadListFn;
    targetHandoverDate: Date | null;
    setTargetHandoverDate: (targetHandoverDate: Date | null) => void;
};

const PurchaseHandoverTab = ({
    count,
    title,
    downloadPurchaseTransactionsFile,
    page,
    pageSize,
    purchaseTransactions,
    search,
    stage,
    createdAt,
    targetHandoverDate,
    setCreatedAt,
    setStage,
    searchOnChange,
    setActivePage,
    setTargetHandoverDate,
}: PurchaseHandoverTabProps) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [showFilterDialog, setShowFilterDialog] = useState<boolean>(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const { attach } = useLoading();

    const downloadFile = useCallback(
        (type: DownloadFileType) => {
            const execute = async () => {
                await downloadPurchaseTransactionsFile({
                    downloadFileType: type,
                    filter: {
                        search,
                        stages: stage === 'all' ? null : [stage],
                        createdAt,
                        handoverAt: targetHandoverDate,
                    },
                });
            };

            attach(execute());
        },
        [attach, createdAt, downloadPurchaseTransactionsFile, search, stage, targetHandoverDate]
    );

    const setValues = useCallback(
        (salesStage, salesCreatedDate, targetHandoverDate) => {
            setStage(salesStage);
            setCreatedAt(salesCreatedDate);
            setTargetHandoverDate(targetHandoverDate);
        },
        [setStage, setCreatedAt, setTargetHandoverDate]
    );

    const header = (
        <PurchaseTransactionsHeader
            downloadExcel={() => downloadFile(DownloadFileType.Excel)}
            downloadPdf={() => downloadFile(DownloadFileType.Pdf)}
            search={search}
            searchOnChange={searchOnChange}
            setShowFilterDialog={setShowFilterDialog}
            setShowFilterMenu={setShowFilterMenu}
            title={title}
        />
    );

    if (count === 0) {
        return (
            <>
                {header}
                <NoRecordsFound />
            </>
        );
    }

    return (
        <>
            <FilterDialog
                createdAt={createdAt}
                open={showFilterDialog}
                setOpen={setShowFilterDialog}
                setValues={setValues}
                stage={stage}
                targetHandoverDate={targetHandoverDate}
            />
            <FilterMenu
                createdAt={createdAt}
                open={showFilterMenu}
                setOpen={setShowFilterMenu}
                setValues={setValues}
                stage={stage}
                targetHandoverDate={targetHandoverDate}
            />
            {header}
            <PurchaseTransactionsBody purchaseTransactions={purchaseTransactions} />
            <ListPagination activePage={page + 1} count={count} pageSize={pageSize} setActivePage={setActivePage} />
            {isSmall && <PurchaseTransactionsFooter />}
        </>
    );
};

export default PurchaseHandoverTab;
