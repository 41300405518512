import { AppBar, Box, Link, Toolbar, Typography } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../useCommonStyles';
import usePublic from '../../utilities/usePublic';
import portalTheme from './portalTheme';
import usePortalStyles from './usePortalStyles';

export type PortalLayoutProps = {
    children: ReactElement | null;
    onLight?: Boolean;
    onBack?: () => void;
};

const PortalLayout = ({ children, onBack, onLight }: PortalLayoutProps): ReactElement => {
    const { t } = useTranslation(['common']);
    const commonStyles = useCommonStyles();
    const classes = usePortalStyles();

    const appvantageLogo = usePublic('assets/images/tradeInAppvantageLogoDark.png');

    return (
        <MuiThemeProvider theme={portalTheme}>
            <div className={onLight ? classes.whiteBackground : classes.root}>
                {onBack && (
                    <AppBar className={onLight ? classes.appBarLight : null}>
                        <Toolbar className={onLight ? classes.toolBarLight : null}>
                            <Link className={classes.linkBack} component="button" onClick={onBack}>
                                <ArrowBackIosIcon className={commonStyles.bigFontSize} />
                                <Typography className={commonStyles.onlyDesktopAndTablet}>
                                    {t('common:portalBackButton')}
                                </Typography>
                            </Link>
                            <Box
                                className={commonStyles.onlyDesktopAndTablet}
                                display="flex"
                                justifyContent="center"
                                position="absolute"
                                width="100%"
                            >
                                <img alt="logo" className={classes.appBarLogo} src={appvantageLogo} />
                            </Box>
                        </Toolbar>
                    </AppBar>
                )}
                {children}
            </div>
        </MuiThemeProvider>
    );
};

export default PortalLayout;
