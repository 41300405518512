import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../../../Session';
import { FullSaleTransactionDataFragment, UserType } from '../../../../api';
import Accordion from '../../../../components/Accordion';
import ContentWithLabel from '../../../../components/ContentWithLabel';
import FileList from '../../../../components/attachments/FileList';

export type HandoverTabProps = {
    saleTransaction: FullSaleTransactionDataFragment;
};

const HandoverTab = ({ saleTransaction }: HandoverTabProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const useCardDesignForAccordion = !isSmall;

    const currentUser = useCurrentUser();

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <Accordion
                    title={t('saleTransactionsPage:accordionTitle.handoverDetails')}
                    useCardDesign={useCardDesignForAccordion}
                    defaultExpanded
                >
                    <Grid spacing={1} container>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={saleTransaction.handover?.personResponsible?.fullName}
                                label={t('saleTransactionsPage:handover.personResponsible')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    saleTransaction.handover?.targetHandoverDateTime
                                        ? t('common:formats.shortDate', {
                                              date: new Date(saleTransaction.handover?.targetHandoverDateTime),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('saleTransactionsPage:handover.targetHandoverDate')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    saleTransaction.handover.handoverLocation
                                        ? saleTransaction.handover.handoverLocation
                                        : t('common:blankValue')
                                }
                                label={t('saleTransactionsPage:handover.handoverLocation')}
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    saleTransaction.handover.actualHandoverDate
                                        ? t('common:formats.shortDate', {
                                              date: new Date(saleTransaction.handover.actualHandoverDate),
                                          })
                                        : t('common:blankValue')
                                }
                                label={t('saleTransactionsPage:handover.actualHandoverDate')}
                            />
                        </Grid>
                        {saleTransaction.awardedBiddingEntry && (
                            <>
                                <Grid xs={12} item>
                                    <ContentWithLabel
                                        content={saleTransaction.awardedBiddingEntry.dealer.fullName}
                                        label={t('saleTransactionsPage:handover.awardedDealer')}
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <ContentWithLabel
                                        content={saleTransaction.awardedBiddingEntry.amount.toLocaleString()}
                                        label={t('saleTransactionsPage:handover.awardedBidAmount')}
                                    />
                                </Grid>
                            </>
                        )}
                        {currentUser.type !== UserType.Dealer && (
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={
                                        saleTransaction.handover.vehicleSalesAgreementNo
                                            ? saleTransaction.handover.vehicleSalesAgreementNo
                                            : t('common:blankValue')
                                    }
                                    display="block"
                                    label={t('saleTransactionsPage:handover.vsaNo')}
                                />
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    saleTransaction.handover.remarks
                                        ? saleTransaction.handover.remarks
                                        : t('common:blankValue')
                                }
                                display="block"
                                label={t('saleTransactionsPage:handover.handoverRemarks')}
                            />
                        </Grid>
                        {currentUser.type !== UserType.Dealer && (
                            <Grid xs={12} item>
                                <ContentWithLabel
                                    content={
                                        saleTransaction.handover.vsaAttachment ? (
                                            <FileList files={[saleTransaction.handover.vsaAttachment]} />
                                        ) : (
                                            <Typography>{t('common:blankValue')}</Typography>
                                        )
                                    }
                                    display="block"
                                    label={t('saleTransactionsPage:handover.vsaAttachment')}
                                />
                            </Grid>
                        )}
                        <Grid xs={12} item>
                            <ContentWithLabel
                                content={
                                    saleTransaction.handover.attachments.length > 0 ? (
                                        <FileList files={saleTransaction.handover.attachments} />
                                    ) : (
                                        <Typography>{t('common:blankValue')}</Typography>
                                    )
                                }
                                display="bloxk"
                                label={t('saleTransactionsPage:handover.otherAttachments')}
                            />
                        </Grid>
                    </Grid>
                </Accordion>
            </Grid>
        </Grid>
    );
};

export default HandoverTab;
