import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SaleTransactionStage } from '../api';

const useSalesTransactionStatus = (transactionStatus: SaleTransactionStage) => {
    const { t } = useTranslation(['saleTransactionsPage']);

    return useMemo(() => {
        switch (transactionStatus) {
            case SaleTransactionStage.BiddingOpen:
                return t('saleTransactionsPage:filter.biddingOpen');

            case SaleTransactionStage.Closed:
                return t('saleTransactionsPage:filter.closed');

            case SaleTransactionStage.Completed:
                return t('saleTransactionsPage:filter.completed');

            case SaleTransactionStage.ForAwarding:
                return t('saleTransactionsPage:filter.forAwarding');

            case SaleTransactionStage.ForHandover:
                return t('saleTransactionsPage:filter.forHandover');

            case SaleTransactionStage.New:
                return t('saleTransactionsPage:filter.new');

            case SaleTransactionStage.PendingApproval:
                return t('saleTransactionsPage:filter.pendingApproval');

            case SaleTransactionStage.UpcomingBidding:
                return t('saleTransactionsPage:filter.upcomingBidding');

            default:
                return '';
        }
    }, [t, transactionStatus]);
};

export default useSalesTransactionStatus;
