import { makeStyles, alpha } from '@material-ui/core/styles';

const usePortalStyles = makeStyles(theme => ({
    root: {
        minHeight: '100vh',
        backgroundColor: '#110D0E',
    },

    whiteBackground: {
        minHeight: '100vh',
        backgroundColor: '#fff',
    },

    linkBack: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem',
        zIndex: 1,
        marginRight: 'auto',
    },

    toolBar: {
        borderBottom: '0.1rem solid #2b2b2b',
        display: 'flex',
        justifyContent: 'center',
    },

    toolBarLight: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fff',
    },

    appBarLight: {
        boxShadow: 'none',
        backgroundColor: '#fff',
        [theme.breakpoints.up('sm')]: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        },
    },

    appBarLogo: {
        height: theme.spacing(5),
    },

    signUpLabel: {
        fontFamily: 'Noto Sans JP!important',
        width: '100%',
        color: 'rgb(255, 255, 255, 0.2)',
        textAlign: 'center',
        fontSize: '4rem',
        fontWeight: 'bold',
        position: 'absolute',
        top: '8.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '5rem',
            top: '12rem',
        },
    },

    logo: {
        height: '5rem',
        backgroundSize: 'Contain',
        [theme.breakpoints.down('sm')]: {
            height: '3.5rem',
        },
    },

    carMedia: {
        height: '12.5rem',
        marginTop: '1rem',
        backgroundSize: 'Cover',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            height: '15rem',
            transform: 'scale(1.2)',
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
                'linear-gradient(0deg, rgba(17,13,14,1) 0%, rgba(17,13,14,0) 10%),' +
                'linear-gradient(90deg, rgba(17,13,14,1) 0%, rgba(17,13,14,0) 5%),' +
                'linear-gradient(180deg, rgba(17,13,14,1) 0%, rgba(17,13,14,0) 10%),' +
                'linear-gradient(270deg, rgba(17,13,14,1) 0%, rgba(17,13,14,0) 5%)',
        },
    },

    inputField: {
        backgroundColor: '#2E2E2E99',
        '& :-webkit-autofill': {
            '-webkit-text-fill-color': '#ED2A74',
            '-webkit-box-shadow': '0 0 0px 1000px #1B1B1B inset',
        },
    },

    inputFieldLight: {
        backgroundColor: '#fff',
        '& :-webkit-autofill': {
            '-webkit-text-fill-color': '#ED2A74',
            '-webkit-box-shadow': '0 0 0px 1000px #1B1B1B inset',
        },
    },

    biometricButton: {
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: theme.spacing(1),
        marginLeft: '1rem',
        '& img': {
            height: '1.5rem',
        },
    },

    loginButton: {
        height: '100%',
    },

    welcomeTitle: {
        color: alpha(theme.palette.secondary.contrastText, 0.5),
        fontSize: '1.125rem',
        fontWeight: 300,
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.725rem',
            marginTop: theme.spacing(3),
        },
    },

    resetPasswordField: {
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.main,
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.secondary.main,
        },
    },
}));

export default usePortalStyles;
