import { Box, Grid, Link } from '@material-ui/core';
import cs from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useCommonStyles from '../../../../useCommonStyles';

const LoginFormFooter = () => {
    const { t } = useTranslation(['loginPage']);
    const commonStyles = useCommonStyles();
    const history = useHistory();

    const [{ value }, { error }] = useField('mobilePhone.number');

    if (!value || error) {
        return null;
    }

    return (
        <Grid xs={12} item>
            <Box display="flex" justifyContent="flex-end">
                <Link
                    className={cs(commonStyles.smallFontSize, commonStyles.primary2)}
                    component="a"
                    onClick={() => history.push('/resetPassword', { mobilePhone: value })}
                >
                    <i>{t('loginPage:forgetPasswordLinkText')}</i>
                </Link>
            </Box>
        </Grid>
    );
};

export default LoginFormFooter;
