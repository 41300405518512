import { Box, Dialog, DialogContent, Typography } from '@material-ui/core';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../useCommonStyles';
import onCloseDialog from '../../../utilities/constants/onCloseDialog';
import usePublic from '../../../utilities/usePublic';

export type SignUpSuccessModalProps = { show?: boolean };

const SignUpSuccessModal = ({ show }: SignUpSuccessModalProps) => {
    const { t } = useTranslation(['signUpPage']);
    const commonStyles = useCommonStyles();

    const done = usePublic('assets/icons/done.svg');

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.dialog }}
            maxWidth="xs"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
            fullWidth
        >
            <DialogContent>
                <Box mb={4} ml={2} mr={2} mt={2} textAlign="center">
                    <img alt="verified-icon" src={done} />
                    <Box mb={1} mt={1}>
                        <Typography className={cs(commonStyles.primary, commonStyles.fontWightMedium)}>
                            {t('signUpPage:successDialog.title')}
                        </Typography>
                    </Box>
                    <Typography className={commonStyles.dialogDescription}>
                        {t('signUpPage:successDialog.body')}
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default SignUpSuccessModal;
