import { Box, CardMedia, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import usePortalStyles from '../../../layouts/PortalLayout/usePortalStyles';
import usePublic from '../../../utilities/usePublic';

const SignUpTitle = () => {
    const { t } = useTranslation(['signUpPage']);
    const portalStyles = usePortalStyles();
    const background = usePublic('assets/images/loginBackground.png');
    const theme = useTheme();

    const isSmall = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid xs={12} item>
            <Box mb={isSmall ? 5 : 4} position="relative">
                <CardMedia className={portalStyles.carMedia} image={background} />
                <Typography className={portalStyles.signUpLabel}>{t('signUpPage:title')}</Typography>
                <Typography align="center" className={cs(portalStyles.welcomeTitle)}>
                    {t('signUpPage:welcomeMessage')}
                </Typography>
            </Box>
        </Grid>
    );
};

export default SignUpTitle;
