import { Grid } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    maxSalesTransactionPhotos,
    maxUploadFileSize,
    pdfFileExtension,
    imageFileExtensions,
} from '../../../../server/schema/constants';
import { AttachmentButton, FrontPhotoManager } from '../../../components/attachments';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';

const maxFileSizeInKB = maxUploadFileSize * 1000 * 1000;
const allowedExtensions = [pdfFileExtension, ...imageFileExtensions];

const TransactionForm = () => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('saleTransactionsPage:transactionDetails.internalRemarks')}
                    name="internalRemarks"
                    placeholder={t('saleTransactionsPage:transactionDetails.internalRemarks')}
                />
            </Grid>
            <Grid xs={12} item>
                <FrontPhotoManager attachmentFieldName="photos" name="frontPagePhoto">
                    {renderPrefix => (
                        <AttachmentButton
                            allowedExtensions={allowedExtensions}
                            buttonText={t('common:upload')}
                            icon={<PublishIcon fontSize="small" />}
                            label={t('saleTransactionsPage:transactionDetails.photos')}
                            max={maxSalesTransactionPhotos}
                            maxSize={maxFileSizeInKB}
                            name="photos"
                            renderPrefix={renderPrefix}
                        />
                    )}
                </FrontPhotoManager>
            </Grid>
        </Grid>
    );
};

export default TransactionForm;
