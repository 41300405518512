import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    FullSaleTransactionDataFragment,
    useUpdateAwardedBiddingEntryMutation,
    useUpdateSaleTransactionToForHandoverMutation,
} from '../../../../api';
import Dialog from '../../../../components/Dialog';
import { useHandleError } from '../../../../utilities/handleErrors';
import ViewBiddingEntry from './ViewBiddingEntry';

export type SelectBidDialogProps = {
    open: boolean;
    onClose: () => void;
    transaction: FullSaleTransactionDataFragment;
};

export type FormValues = {
    biddingEntryId?: string;
};

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            padding: '0 20px',
        },
        formControl: {
            marginTop: '40px',
        },
    })
);

const SelectBidDialog = ({ transaction, open, onClose }: SelectBidDialogProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const [updateAwardee] = useUpdateAwardedBiddingEntryMutation();
    const [updateSaleTransactionToForHandover] = useUpdateSaleTransactionToForHandoverMutation();

    const styles = useStyles();

    const biddingId = transaction.latestBiddingSession?.id;
    const saleTransactionId = transaction.id;

    const entries = transaction.latestBiddingSessionEntry?.topBiddingEntries || [];
    const [selectedBid, setSelectedBid] = useState(entries.length > 0 ? entries[0].id : '');

    const onSubmit = useHandleError(async () => {
        await updateAwardee({
            variables: { biddingId, saleTransactionId, biddingEntryId: selectedBid },
        });

        await updateSaleTransactionToForHandover({
            variables: { transactionId: saleTransactionId },
        });

        onClose();
    }, [biddingId, saleTransactionId, onClose]);

    return (
        <Dialog onCloseClick={onClose} open={open} title={t('saleTransactionsPage:sessionDetails.selectAwardee')}>
            <Formik initialValues={{}} onSubmit={onSubmit}>
                {({ isSubmitting }) => (
                    <Form className={styles.container}>
                        <Grid spacing={1} container>
                            <RadioGroup name="dealer">
                                {entries.map((entry, index) => {
                                    const isSelected = selectedBid === entry.id;

                                    const card = <ViewBiddingEntry entry={entry} index={index} />;

                                    const onChange = () => {
                                        setSelectedBid(entry.id);
                                    };

                                    const radio = (
                                        <Radio
                                            checked={isSelected}
                                            name="biddingEntryId"
                                            onChange={onChange}
                                            value={entry.id}
                                        />
                                    );

                                    return (
                                        <Grid key={entry.id} sm={6} xs={12} item>
                                            <FormControlLabel control={radio} label={card} />
                                        </Grid>
                                    );
                                })}
                            </RadioGroup>
                        </Grid>
                        <Grid className={styles.formControl} spacing={2} container>
                            <FormControl fullWidth>
                                <Button color="secondary" disabled={isSubmitting} type="submit" variant="contained">
                                    {t('common:select')}
                                </Button>
                            </FormControl>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default SelectBidDialog;
