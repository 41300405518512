import {
    FormControl,
    FormHelperText,
    Select,
    SelectProps,
    MenuItem,
    FormLabel,
    ListSubheader,
} from '@material-ui/core';
import { useField } from 'formik';
import { set } from 'lodash/fp';
import React, { useMemo } from 'react';
import useCommonStyles from '../../useCommonStyles';

export interface OutlinedSelectProps extends Omit<SelectProps, 'value' | 'onChange'> {
    name: string;
    options:
        | Array<{ value: string; label: string; isSubHeader?: boolean }>
        | Array<{ value: number; label: string; isSubHeader?: boolean }>
        | Array<{ value: boolean; label: string }>;
}

const OutlinedSelectField = ({ name, options, inputProps, placeholder, label, ...props }: OutlinedSelectProps) => {
    const { disabled } = props;
    const [{ value, ...field }, meta] = useField({ name });
    const commonStyles = useCommonStyles();

    const hasError = !!meta.error && meta.touched;

    const enhancedInputProps = useMemo(() => {
        if (value === '' && placeholder) {
            return set('classes.outlined', commonStyles.placeholderOpacity, inputProps);
        }

        return inputProps;
    }, [inputProps, commonStyles, value, placeholder]);

    return (
        <>
            <FormControl variant="outlined" fullWidth>
                {label && (
                    <FormLabel className={commonStyles.formLabel} disabled={disabled}>
                        {label}
                    </FormLabel>
                )}
                <Select
                    // spread props
                    {...props}
                    // the error is defined from what we go in meta data
                    error={hasError}
                    // provide the value
                    value={value === null ? undefined : value}
                    // then spread the field properties itself
                    {...field}
                    // display empty if there is a placeholder
                    displayEmpty={placeholder !== ''}
                    // provide input props
                    inputProps={enhancedInputProps}
                >
                    {placeholder && (
                        <MenuItem style={{ display: 'none' }} value="" disabled>
                            {placeholder}
                        </MenuItem>
                    )}
                    {options.map(option =>
                        option.isSubHeader ? (
                            <ListSubheader key={option.label}>{option.label}</ListSubheader>
                        ) : (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        )
                    )}
                </Select>
            </FormControl>
            <FormHelperText error={hasError}>{hasError ? meta.error : ''}</FormHelperText>
        </>
    );
};

export default OutlinedSelectField;
