import { Grid, Box, Typography, Button, FormControl, Link } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../components/Accordion';
import useCommonStyles from '../../../../useCommonStyles';
import { VehicleForm } from '../../Forms';
import SaleTransactionsBody from './SaleTransactionsBody';

const AddTransactionBulk = () => {
    const [mode, setMode] = useState<'manual' | 'bulk' | null>(null);

    const commonStyles = useCommonStyles();

    const { t } = useTranslation(['saleTransactionsPage']);

    return (
        <Accordion title={t('saleTransactionsPage:accordionTitle.saleTransaction')} defaultExpanded useCardDesign>
            <Grid spacing={2} container>
                <Grid xs={12} item>
                    <FormControl fullWidth>
                        <Button color="secondary" onClick={() => setMode('bulk')} variant="contained">
                            {t('saleTransactionsPage:button.AddTransactionsBulk')}
                        </Button>
                    </FormControl>
                </Grid>
                <Grid xs={12} item>
                    <Box alignItems="center" display="flex">
                        <Box ml="auto" mr="auto">
                            <Typography className={commonStyles.secondary2}>{t('common:or')}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={12} item>
                    <Box textAlign="center">
                        <Link color="secondary" component="a" onClick={() => setMode('manual')} underline="none">
                            {t('saleTransactionsPage:button.addManually')}
                        </Link>
                    </Box>
                </Grid>
                {mode === 'manual' && (
                    <Grid xs={12} item>
                        <VehicleForm onCreateion />
                    </Grid>
                )}
                <SaleTransactionsBody onClose={() => setMode(null)} open={mode === 'bulk'} />
            </Grid>
        </Accordion>
    );
};

export default AddTransactionBulk;
