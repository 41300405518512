import { CardMedia, Grid } from '@material-ui/core';
import usePortalStyles from '../../../layouts/PortalLayout/usePortalStyles';
import usePublic from '../../../utilities/usePublic';

const LoginHeader = () => {
    const portalStyles = usePortalStyles();

    const image = usePublic('assets/images/tradeInAppvantageLogoLight.png');

    return (
        <Grid xs={12} item>
            <CardMedia className={portalStyles.logo} image={image} />
        </Grid>
    );
};

export default LoginHeader;
