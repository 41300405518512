import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useCurrentUser } from '../../../Session';
import {
    SaleTransactionStage,
    FullSaleTransactionDataFragment,
    UpdateSaleTransactionMutationVariables,
    UserType,
    useUpdateSaleTransactionMutation,
    NameOnlyFileDataFragment,
    useUploadSalesTransactionHandoverAttachmentMutation,
    useRemoveSalesTransactionHandoverAttachmentMutation,
    useUploadVsaAttachmentMutation,
    useRemoveVsaAttachmentMutation,
    useUploadSalesTransactionPhotoMutation,
    useRemoveSalesTransactionPhotoMutation,
    useUpdateSalesTransactionFrontPagePhotoMutation,
    useUpdateBiddingMutation,
} from '../../../api';
import FileViewerProvider from '../../../components/FileViewerProvider';
import { diffUploads } from '../../../utilities/file';
import { useHandleError } from '../../../utilities/handleErrors';
import useHandoverLocationOptions, { defaultOtherLocationOption } from '../../../utilities/useHandoverLocationOptions';
import useValidator from '../../../utilities/useValidator';
import validators from '../../../utilities/validators';
import EditSaleTransactionBody from './EditSaleTransactionBody';
import EditSaleTransactionHeader from './EditSaleTransactionHeader';

export type EditSaleTransactionProps = {
    saleTransaction: FullSaleTransactionDataFragment;
    goToView: () => void;
};

export type EditSaleTransactionFormValues = {
    handover: {
        targetHandoverDate: Date;
        targetHandoverTime: Date;
        handoverLocationField: { main: string; other?: string };
        attachments: (NameOnlyFileDataFragment | File)[];
        vsaAttachments: (NameOnlyFileDataFragment | File)[];
    };
    photos: (NameOnlyFileDataFragment | File)[];
    frontPagePhoto?: NameOnlyFileDataFragment | File;
    biddingSession?: { name: string; notes: string };
} & UpdateSaleTransactionMutationVariables['fields'];

const computeInitialValues = (
    values: FullSaleTransactionDataFragment,
    handoverLocationOptions: Array<{ value: string; label: string }>
): EditSaleTransactionFormValues => {
    const {
        id,
        stage,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        purchaseTransaction,
        latestBiddingSession,
        latestBiddingSessionEntry,
        awardedBiddingEntry,
        handover,
        activities,
        lastActivity,
        identifier,
        frontPagePhotoSourceId,
        ...fields
    } = values;

    const { targetHandoverDateTime, handoverLocation, personResponsible, vsaAttachment, ...handoverFields } = handover;
    const handoverLocationOption = handoverLocationOptions.find(option => option.value === handoverLocation);

    const handoverLocationField = {
        main: handoverLocationOption ? handoverLocationOption.value : defaultOtherLocationOption,
        other: !handoverLocationOption ? handoverLocation : '',
    };

    return {
        ...fields,
        handover: {
            targetHandoverDate: targetHandoverDateTime as Date,
            targetHandoverTime: targetHandoverDateTime as Date,
            handoverLocationField: handoverLocation ? handoverLocationField : { main: '' },
            personResponsible: personResponsible?.id || '',
            targetHandoverDateTime,
            vsaAttachments: vsaAttachment ? [vsaAttachment] : [],
            ...handoverFields,
        },
        biddingSession: latestBiddingSession && {
            name: latestBiddingSession.sessionName,
            notes: latestBiddingSession.notes,
        },
        // switch it with the original document
        frontPagePhoto: fields.photos.find(document => document.id === frontPagePhotoSourceId),
    };
};

const computePayload = (values: EditSaleTransactionFormValues): UpdateSaleTransactionMutationVariables['fields'] => {
    const { frontPagePhoto, photos, handover, biddingSession, ...fields } = values;

    const {
        targetHandoverDate,
        targetHandoverTime,
        handoverLocationField,
        handoverLocation,
        targetHandoverDateTime,
        attachments,
        vsaAttachments,
        personResponsible,
        ...handoverFields
    } = handover;

    let targetHandoverDateTimeNew: Date = null;

    if (targetHandoverDate) {
        targetHandoverDateTimeNew = targetHandoverTime
            ? dayjs(targetHandoverDate)
                  .set('hour', dayjs(targetHandoverTime).hour())
                  .set('minute', dayjs(targetHandoverTime).minute())
                  .toDate()
            : targetHandoverDate;
    }

    return {
        handover: {
            targetHandoverDateTime: targetHandoverDateTimeNew,
            handoverLocation: handoverLocationField.other || handoverLocationField.main,
            personResponsible: personResponsible || null,
            ...handoverFields,
        },
        ...fields,
    };
};

const EditSaleTransaction = ({ saleTransaction, goToView }: EditSaleTransactionProps) => {
    const [updateSaleTransaction] = useUpdateSaleTransactionMutation();
    const [uploadHandoverAttachmentMutation] = useUploadSalesTransactionHandoverAttachmentMutation();
    const [removeHandoverAttachmentMutation] = useRemoveSalesTransactionHandoverAttachmentMutation();
    const [removeSalesTransactionPhotoMutation] = useRemoveSalesTransactionPhotoMutation();
    const [uploadSalesTransactionPhotoMutation] = useUploadSalesTransactionPhotoMutation();
    const [uploadVsaAttachmentMutation] = useUploadVsaAttachmentMutation();
    const [removeVsaAttachmentMutation] = useRemoveVsaAttachmentMutation();
    const [updateSalesTransactionFrontPagePhotoMutation] = useUpdateSalesTransactionFrontPagePhotoMutation();
    const [updateBiddingMutation] = useUpdateBiddingMutation();
    const currentUser = useCurrentUser();

    const initialFrontPagePhotoId = saleTransaction.frontPagePhotoSourceId;
    const initialPhotos = saleTransaction.photos;
    const initialAttachments = saleTransaction.handover.attachments;
    const initialVsaAttachments = saleTransaction.handover.vsaAttachment
        ? [saleTransaction.handover.vsaAttachment]
        : [];

    const onSubmit = useHandleError(
        async (values: EditSaleTransactionFormValues) => {
            const photosDiff = diffUploads(values.photos, initialPhotos);
            const attachmentsDiff = diffUploads(values.handover.attachments, initialAttachments);
            const vsaAttachmentsDiff = diffUploads(values.handover.vsaAttachments, initialVsaAttachments);
            const fields = computePayload(values);
            const { frontPagePhoto, biddingSession } = values;

            await Promise.all([
                ...photosDiff.removedUploads.map(async file => {
                    await removeSalesTransactionPhotoMutation({
                        variables: { salesTransactionId: saleTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...attachmentsDiff.removedUploads.map(async file => {
                    await removeHandoverAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...vsaAttachmentsDiff.removedUploads.map(async file => {
                    await removeVsaAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, uploadedFileId: file.id },
                    });
                }),
                ...photosDiff.newUploads.map(async file => {
                    await uploadSalesTransactionPhotoMutation({
                        variables: {
                            salesTransactionId: saleTransaction.id,
                            file,
                            isFrontPagePhoto: frontPagePhoto === file,
                        },
                    });
                }),
                ...attachmentsDiff.newUploads.map(async file => {
                    await uploadHandoverAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, file },
                    });
                }),
                ...vsaAttachmentsDiff.newUploads.map(async file => {
                    await uploadVsaAttachmentMutation({
                        variables: { salesTransactionId: saleTransaction.id, file },
                    });
                }),
            ]);

            if (!(frontPagePhoto instanceof File) && frontPagePhoto?.id !== initialFrontPagePhotoId) {
                await updateSalesTransactionFrontPagePhotoMutation({
                    variables: { salesTransactionId: saleTransaction.id, frontPagePhotoId: frontPagePhoto?.id },
                });
            }

            if (
                saleTransaction.stage !== SaleTransactionStage.New &&
                saleTransaction.stage !== SaleTransactionStage.Completed &&
                saleTransaction.stage !== SaleTransactionStage.Closed
            ) {
                const { latestBiddingSession } = saleTransaction;

                await updateBiddingMutation({
                    variables: {
                        biddingId: latestBiddingSession.id,
                        fields: {
                            sessionName: biddingSession.name,
                            notes: biddingSession.notes,
                        },
                    },
                });
            }

            await updateSaleTransaction({
                variables: { transactionId: saleTransaction.id, fields },
            });

            goToView();
        },
        [saleTransaction, goToView, initialFrontPagePhotoId, initialPhotos]
    );

    const formValidator = useMemo(
        () =>
            validators.compose(
                // validation for admin
                validators.only(
                    () => currentUser.type === UserType.Admin,
                    validators.compose(
                        validators.requiredString('vehicle.number'),
                        validators.requiredNumber('vehicle.mileage'),
                        validators.requiredDate('vehicle.intendedDeregistrationDate'),
                        validators.requiredString('vehicle.make'),
                        validators.requiredString('vehicle.model'),
                        validators.requiredString('vehicle.primaryColour'),
                        validators.requiredNumber('vehicle.manufacturingYear'),
                        validators.requiredString('vehicle.engineNumber'),
                        validators.requiredString('vehicle.chassisNumber'),
                        validators.requiredString('vehicle.maximumPowerOutput'),
                        validators.requiredNumber('vehicle.openMarketValue'),
                        validators.requiredDate('vehicle.originalRegistrationDate'),
                        validators.requiredDate('vehicle.firstRegistrationDate'),
                        validators.requiredNumber('vehicle.transferCount'),
                        validators.requiredNumber('vehicle.actualARFPaid'),
                        validators.requiredDate('vehicle.coeExpiryDate'),
                        validators.requiredString('vehicle.coeCategory'),
                        validators.requiredNumber('vehicle.coePeriodYear'),
                        validators.only(
                            ({ vehicle: { parfEligibility } }) => parfEligibility === true,
                            validators.compose(validators.requiredString('vehicle.parfEligibilityDate'))
                        )
                    )
                ),

                validators.only(
                    () => saleTransaction.stage === SaleTransactionStage.ForAwarding,
                    validators.compose(
                        validators.only(
                            () =>
                                currentUser.type === UserType.Admin ||
                                currentUser.type === UserType.ValuationTeam ||
                                currentUser.type === UserType.Approver,
                            validators.compose(
                                validators.requiredString('handover.personResponsible'),
                                validators.requiredString('handover.targetHandoverDate'),
                                validators.requiredString('handover.targetHandoverTime')
                            )
                        ),

                        validators.only(
                            () => currentUser.type === UserType.Admin || currentUser.type === UserType.Approver,
                            validators.compose(validators.requiredString('handover.vehicleSalesAgreementNo'))
                        )
                    )
                )
            ),
        [currentUser, saleTransaction]
    );

    const validate = useValidator(formValidator);

    const handoverLocationOptions = useHandoverLocationOptions();
    const initialValues = useMemo(
        () => computeInitialValues(saleTransaction, handoverLocationOptions),
        [handoverLocationOptions, saleTransaction]
    );

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <FileViewerProvider>
                        <EditSaleTransactionHeader goToView={goToView} />
                        <EditSaleTransactionBody saleTransaction={saleTransaction} />
                    </FileViewerProvider>
                </Form>
            )}
        </Formik>
    );
};

export default EditSaleTransaction;
