import { Grid } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    maxSalesTransactionHandoverAttachments,
    maxUploadFileSize,
    pdfFileExtension,
    imageFileExtensions,
} from '../../../../server/schema/constants';
import { useCurrentUser } from '../../../Session';
import { useGetUserListByTypeQuery, UserType, SaleTransactionStage } from '../../../api';
import { AttachmentButton } from '../../../components/attachments';
import OutlinedDatePickerField from '../../../components/fields/OutlinedDatePickerField';
import OutlinedInputField from '../../../components/fields/OutlinedInputField';
import OutlinedSelectField from '../../../components/fields/OutlinedSelectField';
import OutlinedTimePickerField from '../../../components/fields/OutlinedTimePickerField';
import useCommonStyles from '../../../useCommonStyles';
import useHandoverLocationOptions, { defaultOtherLocationOption } from '../../../utilities/useHandoverLocationOptions';
import { EditSaleTransactionFormValues } from '../EditSaleTransactions';

const maxFileSizeInKB = maxUploadFileSize * 1000 * 1000;

export type HandoverFormProps = {
    currentStage?: SaleTransactionStage;
};

const allowedExtensions = [pdfFileExtension, ...imageFileExtensions];

const HandoverForm = ({ currentStage }: HandoverFormProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const { values, setFieldValue } = useFormikContext<EditSaleTransactionFormValues>();
    const { vehicle, handover } = values;
    const currentUser = useCurrentUser();
    const commonStyles = useCommonStyles();

    useEffect(() => {
        if (handover.handoverLocationField.main !== defaultOtherLocationOption) {
            // remove other location
            setFieldValue('handover.handoverLocationField.other', '');
        }
    }, [handover.handoverLocationField, setFieldValue]);

    useEffect(() => {
        setFieldValue('handover.targetHandoverDate', vehicle.intendedDeregistrationDate);
    }, [vehicle.intendedDeregistrationDate, setFieldValue]);

    const handoverLocationOptions = useHandoverLocationOptions();

    const { data: valuationTeamUserData } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.ValuationTeam,
        },
    });

    const valuationTeamUserOptions = useMemo(
        () =>
            (valuationTeamUserData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [valuationTeamUserData]
    );

    const { data: adminUserData } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.Admin,
        },
    });

    const adminUserOptions = useMemo(
        () =>
            (adminUserData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [adminUserData]
    );

    const { data: approverUserData } = useGetUserListByTypeQuery({
        fetchPolicy: 'cache-and-network',
        variables: {
            type: UserType.Approver,
        },
    });

    const approverUserOptions = useMemo(
        () =>
            (approverUserData?.users?.items || []).map(user => ({
                value: user.id,
                label: user.fullName,
            })),
        [approverUserData]
    );

    const personResponsibleOptions = adminUserOptions.concat(approverUserOptions).concat(valuationTeamUserOptions);

    return (
        <Grid spacing={2} container>
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.Approver &&
                        currentUser.type !== UserType.ValuationTeam
                    }
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('saleTransactionsPage:handover.personResponsible')}
                    name="handover.personResponsible"
                    options={personResponsibleOptions}
                    placeholder={t('saleTransactionsPage:handover.personResponsible')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.Approver &&
                        currentUser.type !== UserType.ValuationTeam
                    }
                    label={t('saleTransactionsPage:handover.targetHandoverDate')}
                    name="handover.targetHandoverDate"
                    placeholder={t('saleTransactionsPage:handover.targetHandoverDate')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedTimePickerField
                    color="secondary"
                    disabled={
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.Approver &&
                        currentUser.type !== UserType.ValuationTeam
                    }
                    label={t('saleTransactionsPage:handover.targetHandoverTime')}
                    name="handover.targetHandoverTime"
                    placeholder={t('saleTransactionsPage:handover.targetHandoverTime')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedSelectField
                    color="secondary"
                    disabled={
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.SaleConsultant &&
                        currentUser.type !== UserType.Approver &&
                        currentUser.type !== UserType.ValuationTeam
                    }
                    inputProps={{
                        classes: {
                            icon: commonStyles.fillSecondary,
                        },
                    }}
                    label={t('saleTransactionsPage:handover.targetHandoverLocation')}
                    name="handover.handoverLocationField.main"
                    options={handoverLocationOptions}
                    placeholder={t('saleTransactionsPage:handover.targetHandoverLocation')}
                />
            </Grid>
            {handover.handoverLocationField.main === defaultOtherLocationOption && (
                <Grid xs={12} item>
                    <OutlinedInputField
                        color="secondary"
                        disabled={
                            currentUser.type !== UserType.Admin &&
                            currentUser.type !== UserType.Approver &&
                            currentUser.type !== UserType.ValuationTeam
                        }
                        label={t('saleTransactionsPage:handover.otherLocation')}
                        name="handover.handoverLocationField.other"
                        placeholder={t('saleTransactionsPage:handover.otherLocation')}
                    />
                </Grid>
            )}
            <Grid xs={12} item>
                <OutlinedDatePickerField
                    color="secondary"
                    disabled={
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.Approver &&
                        currentUser.type !== UserType.ValuationTeam
                    }
                    label={t('saleTransactionsPage:handover.actualHandoverDate')}
                    name="handover.actualHandoverDate"
                    placeholder={t('saleTransactionsPage:handover.actualHandoverDate')}
                />
            </Grid>
            <Grid xs={12} item>
                <OutlinedInputField
                    color="secondary"
                    label={t('saleTransactionsPage:handover.vsaNo')}
                    name="handover.vehicleSalesAgreementNo"
                    placeholder={t('saleTransactionsPage:handover.vsaNo')}
                />
            </Grid>
            <Grid xs={12} item>
                <AttachmentButton
                    allowedExtensions={allowedExtensions}
                    buttonText={t('common:upload')}
                    disabled={
                        currentStage === SaleTransactionStage.Completed ||
                        currentStage === SaleTransactionStage.Closed ||
                        (currentUser.type !== UserType.Admin &&
                            currentUser.type !== UserType.Approver &&
                            currentUser.type !== UserType.ValuationTeam)
                    }
                    icon={<PublishIcon fontSize="small" />}
                    label={t('saleTransactionsPage:handover.vsaAttachment')}
                    max={1}
                    maxSize={maxFileSizeInKB}
                    name="handover.vsaAttachments"
                />
            </Grid>
            <Grid xs={12} item>
                <AttachmentButton
                    allowedExtensions={allowedExtensions}
                    buttonText={t('common:upload')}
                    disabled={
                        currentUser.type !== UserType.Admin &&
                        currentUser.type !== UserType.Approver &&
                        currentUser.type !== UserType.ValuationTeam
                    }
                    icon={<PublishIcon fontSize="small" />}
                    label={t('saleTransactionsPage:handover.otherAttachments')}
                    max={maxSalesTransactionHandoverAttachments}
                    maxSize={maxFileSizeInKB}
                    name="handover.attachments"
                />
            </Grid>
        </Grid>
    );
};

export default HandoverForm;
