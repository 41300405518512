import { Box, IconButton, Typography, useMediaQuery, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../Session';
import { FullSaleTransactionDataFragment, UserType, SaleTransactionStage } from '../../../api';
import MobileAppBar from '../../../components/MobileAppBar';
import useCommonStyles from '../../../useCommonStyles';

export type ViewSaleTransactionHeaderProps = {
    saleTransaction: FullSaleTransactionDataFragment;
    goToEdition: () => void;
    downloadPdf?: () => void;
    downloadExcel?: () => void;
};

const ViewSaleTransactionHeader = ({
    saleTransaction,
    goToEdition,
    downloadPdf,
    downloadExcel,
}: ViewSaleTransactionHeaderProps) => {
    const { t } = useTranslation(['saleTransactionsPage', 'common']);
    const commonStyles = useCommonStyles();
    const history = useHistory();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const currentUser = useCurrentUser();

    const canEdit =
        saleTransaction.stage !== SaleTransactionStage.Completed &&
        (currentUser.type === UserType.Admin ||
            currentUser.type === UserType.Approver ||
            currentUser.type === UserType.ValuationTeam);

    if (isSmall) {
        return (
            <MobileAppBar>
                <Box zIndex={1}>
                    <IconButton className={commonStyles.primary2} onClick={history.goBack}>
                        <ArrowBackIosIcon className={commonStyles.bigFontSize} />
                    </IconButton>
                </Box>
                <Box display="flex" justifyContent="center" position="absolute" pr={4} width="100%">
                    <Typography className={commonStyles.fontWeightBold}>
                        {t('saleTransactionsPage:vehicleDetails')}
                    </Typography>
                </Box>
                <Box display="flex" ml="auto" zIndex={1}>
                    {currentUser.type !== UserType.SaleConsultant && currentUser.type !== UserType.Dealer && (
                        <IconButton className={commonStyles.primary2} onClick={downloadPdf}>
                            <GetAppIcon />
                        </IconButton>
                    )}
                    {canEdit && (
                        <IconButton className={commonStyles.primary2} onClick={goToEdition}>
                            <EditIcon />
                        </IconButton>
                    )}
                </Box>
            </MobileAppBar>
        );
    }

    return (
        <Box alignItems="center" className={commonStyles.header} display="flex">
            <Typography className={commonStyles.title}>{t('saleTransactionsPage:vehicleDetails')}</Typography>
            <Box ml="auto">
                <Box className={commonStyles.buttonGroup}>
                    {currentUser.type !== UserType.SaleConsultant && currentUser.type !== UserType.Dealer && (
                        <>
                            <Button color="secondary" onClick={downloadExcel} variant="contained">
                                <GetAppIcon className={commonStyles.normalFontSize} fontSize="small" />{' '}
                                {t('common:excel')}
                            </Button>
                            <Button color="secondary" onClick={downloadPdf} variant="contained">
                                <GetAppIcon className={commonStyles.normalFontSize} fontSize="small" />{' '}
                                {t('common:pdf')}
                            </Button>
                        </>
                    )}
                    {canEdit && (
                        <Button color="secondary" onClick={goToEdition} variant="contained">
                            {t('common:edit')}
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ViewSaleTransactionHeader;
