import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import { useCurrentUser } from '../../Session';
import { SaleTransactionStage, UserType } from '../../api';
import useCommonStyles from '../../useCommonStyles';
import CarIcon from '../../icons/carIcon.svg';

export type MobileMenuTabsProps = {
    a11yProps: (index: string) => object;
    value: string;
    setValue: (value: string) => void;
    stage: SaleTransactionStage;
};

const MobileMenuTabs = ({ value, setValue, a11yProps, stage }: MobileMenuTabsProps) => {
    const { t } = useTranslation(['saleTransactionsPage']);
    const commonStyles = useCommonStyles();

    const currentUser = useCurrentUser();

    const showHandoverTab =
        currentUser.type !== UserType.Dealer ||
        [
            SaleTransactionStage.ForHandover,
            SaleTransactionStage.PendingApproval,
            SaleTransactionStage.Completed,
            SaleTransactionStage.Closed,
        ].includes(stage);

    return (
        <BottomNavigation
            className={commonStyles.bottomNavigation}
            onChange={(_, value) => setValue(value)}
            value={value}
        >
            {currentUser.type !== UserType.Dealer && (
                <BottomNavigationAction
                    className={commonStyles.bottomNavigationAction}
                    classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
                    icon={<InfoIcon />}
                    label={t('saleTransactionsPage:menu.transaction')}
                    value="transaction"
                    showLabel
                    {...a11yProps('transaction')}
                />
            )}
            <BottomNavigationAction
                className={commonStyles.bottomNavigationAction}
                classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
                icon={<CarIcon className="MuiSvgIcon-root" />}
                label={t('saleTransactionsPage:menu.vehicle')}
                value="vehicle"
                showLabel
                {...a11yProps('vehicle')}
            />
            <BottomNavigationAction
                className={commonStyles.bottomNavigationAction}
                classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
                icon={<InfoIcon />}
                label={t('saleTransactionsPage:menu.bidding')}
                value="bidding"
                showLabel
                {...a11yProps('bidding')}
            />
            {showHandoverTab && (
                <BottomNavigationAction
                    className={commonStyles.bottomNavigationAction}
                    classes={{ selected: commonStyles.bottomNavigationSelected, label: commonStyles.smallFontSize }}
                    icon={<AssignmentIndIcon />}
                    label={t('saleTransactionsPage:menu.handover')}
                    value="handover"
                    showLabel
                    {...a11yProps('handover')}
                />
            )}
        </BottomNavigation>
    );
};

export default MobileMenuTabs;
