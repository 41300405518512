import { Box, CardMedia, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useCurrentUser } from '../../../../Session';
import { UserType } from '../../../../api';
import { usePublicFn } from '../../../../utilities/usePublic';
import { SidePanelMenuProps } from './DrawerHeader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '50px',
        },
        itemWrapper: {
            display: 'flex',
            color: theme.palette.primary.contrastText,
            marginBottom: '16px',
            paddingLeft: '40px',
            height: '56px',
            '&:hover': {
                background: theme.palette.secondary.main,
            },
        },
        selected: {
            background: theme.palette.secondary.main,
        },
        item: {
            display: 'flex',
            maxWidth: '256px',
        },
        iconContainer: {
            minWidth: '24px',
            height: '24px',
            margin: 'auto',
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        icon: {
            width: '24px',
            height: '24px',
        },
    })
);

const SidePanelMenu = ({ items, open }: SidePanelMenuProps) => {
    const { t } = useTranslation(['homePage']);

    const location = useLocation();
    const history = useHistory();

    const user = useCurrentUser();

    const styles = useStyles();
    const redirect = useCallback((path: string) => () => history.push(path), [history]);

    const isActivePath = useCallback(
        (path: string) => location.pathname.toLowerCase() === path.toLowerCase(),
        [location.pathname]
    );

    const publicFn = usePublicFn();

    return (
        <List className={styles.root}>
            {user.type === UserType.Admin &&
                items.map(menu => {
                    const classes = isActivePath(menu.to)
                        ? cs(styles.itemWrapper, styles.selected)
                        : styles.itemWrapper;

                    return (
                        <ListItem key={menu.text} className={classes} onClick={redirect(menu.to)} button>
                            <Box className={styles.item}>
                                <ListItemIcon className={styles.iconContainer}>
                                    <CardMedia className={styles.icon} image={menu.icon} />
                                </ListItemIcon>
                                {open && <ListItemText primary={menu.text} />}
                            </Box>
                        </ListItem>
                    );
                })}

            {user.type === UserType.SaleConsultant && (
                <ListItem
                    className={
                        isActivePath('/myTransactions') ? cs(styles.itemWrapper, styles.selected) : styles.itemWrapper
                    }
                    onClick={redirect('/myTransactions')}
                    button
                >
                    <Box className={styles.item}>
                        <ListItemIcon className={styles.iconContainer}>
                            <CardMedia className={styles.icon} image={publicFn('assets/menuIcons/Group_6028.svg')} />
                        </ListItemIcon>
                        <ListItemText primary={t('homePage:menuBar.myTransactions')} />
                    </Box>
                </ListItem>
            )}

            {user.type === UserType.Dealer && (
                <>
                    <ListItem
                        className={
                            isActivePath('/myBids') ? cs(styles.itemWrapper, styles.selected) : styles.itemWrapper
                        }
                        onClick={redirect('/myBids')}
                        button
                    >
                        <Box className={styles.item}>
                            <ListItemIcon className={styles.iconContainer}>
                                <CardMedia
                                    className={styles.icon}
                                    image={publicFn('assets/menuIcons/Group_6028.svg')}
                                />
                            </ListItemIcon>
                            <ListItemText primary={t('homePage:menuBar.myBids')} />
                        </Box>
                    </ListItem>
                    <ListItem
                        className={
                            isActivePath('/allBiddingSessions')
                                ? cs(styles.itemWrapper, styles.selected)
                                : styles.itemWrapper
                        }
                        onClick={redirect('/allBiddingSessions')}
                        button
                    >
                        <Box className={styles.item}>
                            <ListItemIcon className={styles.iconContainer}>
                                <CardMedia
                                    className={styles.icon}
                                    image={publicFn('assets/menuIcons/Group_6028.svg')}
                                />
                            </ListItemIcon>
                            <ListItemText primary={t('homePage:menuBar.allBiddingSessions')} />
                        </Box>
                    </ListItem>
                </>
            )}

            {user.type === UserType.Approver && (
                <>
                    <ListItem
                        className={
                            isActivePath('/transactionApprovals')
                                ? cs(styles.itemWrapper, styles.selected)
                                : styles.itemWrapper
                        }
                        onClick={redirect('/transactionApprovals')}
                        button
                    >
                        <Box className={styles.item}>
                            <ListItemIcon className={styles.iconContainer}>
                                <CardMedia
                                    className={styles.icon}
                                    image={publicFn('assets/menuIcons/Group_6028.svg')}
                                />
                            </ListItemIcon>
                            <ListItemText primary={t('homePage:menuBar.approvals')} />
                        </Box>
                    </ListItem>
                    <ListItem
                        className={
                            isActivePath('/approverBiddingSessions')
                                ? cs(styles.itemWrapper, styles.selected)
                                : styles.itemWrapper
                        }
                        onClick={redirect('/approverBiddingSessions')}
                        button
                    >
                        <Box className={styles.item}>
                            <ListItemIcon className={styles.iconContainer}>
                                <CardMedia
                                    className={styles.icon}
                                    image={publicFn('assets/menuIcons/Group_6029.svg')}
                                />
                            </ListItemIcon>
                            <ListItemText primary={t('homePage:menuBar.biddingSessions')} />
                        </Box>
                    </ListItem>
                </>
            )}

            {user.type === UserType.ValuationTeam && (
                <>
                    <ListItem
                        className={
                            isActivePath('/myTransactions')
                                ? cs(styles.itemWrapper, styles.selected)
                                : styles.itemWrapper
                        }
                        onClick={redirect('/myTransactions')}
                        button
                    >
                        <Box className={styles.item}>
                            <ListItemIcon className={styles.iconContainer}>
                                <CardMedia
                                    className={styles.icon}
                                    image={publicFn('assets/menuIcons/Group_6028.svg')}
                                />
                            </ListItemIcon>
                            <ListItemText primary={t('homePage:menuBar.myTransactions')} />
                        </Box>
                    </ListItem>
                    <ListItem
                        className={
                            isActivePath('/purchaseTransactions')
                                ? cs(styles.itemWrapper, styles.selected)
                                : styles.itemWrapper
                        }
                        onClick={redirect('/purchaseTransactions')}
                        button
                    >
                        <Box className={styles.item}>
                            <ListItemIcon className={styles.iconContainer}>
                                <CardMedia
                                    className={styles.icon}
                                    image={publicFn('assets/menuIcons/Group_6028.svg')}
                                />
                            </ListItemIcon>
                            <ListItemText primary={t('homePage:menuBar.purchaseTransactionRecords')} />
                        </Box>
                    </ListItem>
                    <ListItem
                        className={
                            isActivePath('/salesTransactions')
                                ? cs(styles.itemWrapper, styles.selected)
                                : styles.itemWrapper
                        }
                        onClick={redirect('/salesTransactions')}
                        button
                    >
                        <Box className={styles.item}>
                            <ListItemIcon className={styles.iconContainer}>
                                <CardMedia
                                    className={styles.icon}
                                    image={publicFn('assets/menuIcons/Group_6029.svg')}
                                />
                            </ListItemIcon>
                            <ListItemText primary={t('homePage:menuBar.salesTransactionRecords')} />
                        </Box>
                    </ListItem>
                </>
            )}
        </List>
    );
};

export default SidePanelMenu;
