import { Grid, Box, Button, Dialog, DialogContent, Typography, FormControl } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { useTranslation } from 'react-i18next';
import useCommonStyles from '../../../../useCommonStyles';
import onCloseDialog from '../../../../utilities/constants/onCloseDialog';
import usePublic from '../../../../utilities/usePublic';

export type AcceptValuationConfirmDialogProps = {
    show?: boolean;
    resolve: (result: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: 0,
        },
        button: {
            borderBottom: 'none',
            borderRadius: 0,
            width: '50%',
            '&:first-child': {
                borderLeft: 'none',
                borderRight: 'none',
            },
            '&:last-child': {
                borderRight: 'none',
            },
        },
        body: {
            whiteSpace: 'pre-line',
        },
        cancelLabel: {
            color: '#A5A5A5',
        },
    })
);

const DuplicateTransactionsConfirmDialog = ({ show, resolve }: AcceptValuationConfirmDialogProps) => {
    const { t } = useTranslation(['purchaseTransactionsPage']);
    const commonStyles = useCommonStyles();
    const styles = useStyles();

    const exclamation = usePublic('assets/icons/exclamation.svg');

    return (
        <Dialog
            aria-labelledby="submitted-dialog-title"
            classes={{ paper: commonStyles.defaultBorderRadius }}
            maxWidth="xs"
            onClose={onCloseDialog}
            open={show}
            disableEscapeKeyDown
        >
            <DialogContent className={styles.dialogContent}>
                <Box mb={4} ml={4} mr={4} mt={2} textAlign="center">
                    <Box>
                        <img alt="exclamation-icon" src={exclamation} />
                    </Box>
                    <Box mb={2} mt={1}>
                        <Typography className={cs(commonStyles.primary, commonStyles.fontWightMedium)}>
                            {t('purchaseTransactionsPage:duplicateTransactionsDialog.title')}
                        </Typography>
                    </Box>
                    <Typography className={commonStyles.dialogDescription}>
                        {t('purchaseTransactionsPage:duplicateTransactionsDialog.body')}
                    </Typography>

                    <Box mt={3}>
                        <Grid spacing={2} container>
                            <Grid xs={6} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={cs(commonStyles.removeBoxShadow, commonStyles.defaultBorderRadius)}
                                        classes={{ label: styles.cancelLabel }}
                                        onClick={() => resolve(true)}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:acceptDialog.button.cancel')}
                                    </Button>
                                </FormControl>
                            </Grid>
                            <Grid xs={6} item>
                                <FormControl fullWidth>
                                    <Button
                                        className={commonStyles.defaultBorderRadius}
                                        color="primary"
                                        onClick={() => resolve(false)}
                                        size="large"
                                        variant="contained"
                                    >
                                        {t('purchaseTransactionsPage:acceptDialog.button.continue')}
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DuplicateTransactionsConfirmDialog;
