import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../../Session';
import { CreateSaleTransactionMutationVariables, useCreateSaleTransactionMutation, UserType } from '../../../api';
import { useHandleError } from '../../../utilities/handleErrors';
import useValidator from '../../../utilities/useValidator';
import validators from '../../../utilities/validators';
import CreateSaleTransactionBody from './CreateSaleTransactionBody';
import CreateSaleTransactionFooter from './CreateSaleTransactionFooter';
import CreateSaleTransactionHeader from './CreateSaleTransactionHeader';

export type CreateSaleTransactionFormValues = CreateSaleTransactionMutationVariables['fields'];

const CreateSaleTransaction = () => {
    const currentUser = useCurrentUser();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const history = useHistory();
    const [createSaleTransaction] = useCreateSaleTransactionMutation();

    const onSubmit = useHandleError(
        async (values: CreateSaleTransactionFormValues) => {
            await createSaleTransaction({
                variables: { fields: values },
            });

            history.goBack();
        },
        [createSaleTransaction, history]
    );

    const formValidator = useMemo(
        () =>
            validators.compose(
                // validation for admin
                validators.only(
                    () => currentUser.type === UserType.Admin,
                    validators.compose(
                        validators.requiredString('vehicle.number'),
                        validators.requiredNumber('vehicle.mileage'),
                        validators.requiredDate('vehicle.intendedDeregistrationDate'),
                        validators.requiredString('vehicle.make'),
                        validators.requiredString('vehicle.model'),
                        validators.requiredString('vehicle.primaryColour'),
                        validators.requiredNumber('vehicle.manufacturingYear'),
                        validators.requiredString('vehicle.engineNumber'),
                        validators.requiredString('vehicle.chassisNumber'),
                        validators.requiredString('vehicle.maximumPowerOutput'),
                        validators.requiredNumber('vehicle.openMarketValue'),
                        validators.requiredDate('vehicle.originalRegistrationDate'),
                        validators.requiredDate('vehicle.firstRegistrationDate'),
                        validators.requiredNumber('vehicle.transferCount'),
                        validators.requiredNumber('vehicle.actualARFPaid'),
                        validators.requiredDate('vehicle.coeExpiryDate'),
                        validators.requiredString('vehicle.coeCategory'),
                        validators.requiredNumber('vehicle.coePeriodYear'),
                        validators.requiredNumber('vehicle.setOfKeys'),
                        validators.requiredString('vehicle.importMethod'),
                        validators.only(
                            ({ vehicle: { parfEligibility } }) => parfEligibility === true,
                            validators.compose(validators.requiredDate('vehicle.parfEligibilityDate'))
                        )
                    )
                )
            ),
        [currentUser]
    );

    const validate = useValidator(formValidator);

    const values: CreateSaleTransactionFormValues = useMemo(
        () => ({
            vehicle: {
                number: '',
                beExported: true,
                intendedDeregistrationDate: null,
                make: '',
                model: '',
                primaryColour: '',
                manufacturingYear: null,
                engineNumber: '',
                chassisNumber: '',
                maximumPowerOutput: '',
                openMarketValue: null,
                originalRegistrationDate: null,
                firstRegistrationDate: null,
                transferCount: null,
                actualARFPaid: null,
                parfEligibility: true,
                parfEligibilityDate: null,
                coeExpiryDate: null,
                coeCategory: '',
                coePeriodYear: null,
                mileage: null,
                importMethod: '',
                secondaryColour: '',
                opcCashRebateAmount: null,
                opcCashRebateEligibilityExpiryDate: null,
                opcCashRebateEligibility: '',
                pqpPaid: null,
                totalRebateAmount: null,
                ltaMessage: '',
                coeRebateAmount: null,
                parfRebateAmount: null,
                qpPaid: null,
                setOfKeys: null,
                ownerHandbookCollected: false,
            },
        }),
        []
    );

    return (
        <Formik initialValues={values} onSubmit={onSubmit} validate={validate}>
            {() => (
                <Form>
                    <CreateSaleTransactionHeader />
                    <CreateSaleTransactionBody />
                    {isSmall && <CreateSaleTransactionFooter />}
                </Form>
            )}
        </Formik>
    );
};

export default CreateSaleTransaction;
